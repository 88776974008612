import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import '../styles/GlobalStyles.css';

const FooterContainer = styled(Box)({
    textAlign: 'center',
    padding: '20px 0',
    backgroundColor: '#f8f9fa',
    borderTop: '1px solid #e9ecef',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
});

const FooterLink = styled(Link)({
    color: '#007bff',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const Footer = () => {
    return (
        <FooterContainer>
            <Typography variant="body1" gutterBottom>
                &copy; {new Date().getFullYear()} Korembi International. All rights reserved.
            </Typography>
            <FooterLink href="https://www.korembi.com" target="_blank" rel="noopener noreferrer">
                Visit our website!
            </FooterLink>
        </FooterContainer>
    );
};

export default Footer;

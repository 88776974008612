import React, { useState } from 'react';
import { Box, Button, Typography, FormControl, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import twemoji from 'twemoji';
import { speakWord } from '../utils/SpeakWords';

const Question = ({ index, question, word, options, selectedAnswer, isDisabled, showFeedback, correctAnswer, onChange, emoji, isCorrect }) => {
  const emojiHTML = twemoji.parse(emoji, { folder: 'svg', ext: '.svg' });

  const handleButtonClick = (option) => {
    if (!isDisabled) {
      onChange(question)(option);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', marginBottom: '20px', border: `1px solid ${isCorrect === true ? 'green' : isCorrect === false ? 'red' : '#ccc'}`, borderRadius: '10px', padding: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
        {showFeedback && (
          <Box sx={{ marginRight: '10px' }}>
            {isCorrect ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}
          </Box>
        )}
        <Typography variant="h6" gutterBottom>{`${index + 1}) ${question}`}</Typography>
      </Box>
      <Box sx={{ marginBottom: '20px', fontSize: '1.2rem', padding: '10px', border: '1px solid #ccc', borderRadius: '10px' }}>{word}</Box>
      <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={1} justifyContent="center">
          {options.map((option, i) => (
            <Grid item xs={6} key={i}>
              <Box
                component={Button}
                variant="contained"
                onClick={() => handleButtonClick(option.text)}
                disabled={isDisabled}
                sx={{
                  width: '15vw',
                  height: '10vh',
                  margin: '5px',
                  backgroundColor: selectedAnswer === option.text ? '#1976d2' : '#e0e0e0',
                  color: selectedAnswer === option.text ? 'white' : 'black',
                  opacity: isDisabled ? 0.5 : 1,
                  pointerEvents: isDisabled ? 'none' : 'auto',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: selectedAnswer === option.text ? '#115293' : '#f5f5f5',
                    transform: isDisabled ? 'none' : 'scale(1.05)',
                  },
                  borderRadius: '12px', // Rounded corners
                  padding: '10px', // Spacing inside buttons
                }}
              >
                <Box className="emoji-container" sx={{ textAlign: 'center' }}>
                  <Box component="span" dangerouslySetInnerHTML={{ __html: twemoji.parse(option.emoji, { folder: 'svg', ext: '.svg' }) }} />
                  <Typography variant="body1">{option.text}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </FormControl>
    </Box>
  );
};

const PictureQuiz = ({ quizData, onComplete, onNext, langCode }) => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [allCorrect, setAllCorrect] = useState(false);

  const handleChange = (question) => (value) => {
    const newAnswers = { ...answers, [question]: value };
    setAnswers(newAnswers);
    speakWord(value, langCode);
    console.log(`Updated answers:`, newAnswers); // Debugging log
  };

  const handleSubmit = () => {
    const correctness = quizData.reduce((acc, item) => {
      acc[item.question] = answers[item.question] === item.correctAnswer;
      return acc;
    }, {});
    setCorrectAnswers(correctness);
    setSubmitted(true);
    setAllCorrect(Object.values(correctness).every(Boolean));
    onComplete(answers);
    console.log(`Correctness:`, correctness); // Debugging log
  };

  const handleRetry = () => {
    const newAnswers = { ...answers };
    const newCorrectAnswers = { ...correctAnswers };
    quizData.forEach((item) => {
      if (correctAnswers[item.question]) {
        // Keep correct answers locked and retain the answer
        newAnswers[item.question] = item.correctAnswer;
      } else {
        // Reset incorrect answers and make them interactable
        delete newAnswers[item.question];
        newCorrectAnswers[item.question] = false; // Maintain incorrect border color and feedback icon
      }
    });
    setAnswers(newAnswers);
    setSubmitted(false);
    setCorrectAnswers(newCorrectAnswers);
    setAllCorrect(false);
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: '800px', margin: 'auto', boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
      <Typography variant="h4" gutterBottom>Quiz</Typography>
      {quizData.map((item, index) => (
        <Question
          key={index}
          index={index}
          question={item.question}
          word={item.word}
          options={item.options}
          selectedAnswer={answers[item.question]}
          correctAnswer={item.correctAnswer}
          isDisabled={submitted}
          showFeedback={submitted || correctAnswers[item.question] !== undefined}
          onChange={handleChange}
          emoji={item.emoji}
          isCorrect={correctAnswers[item.question] !== false && correctAnswers[item.question] !== undefined ? correctAnswers[item.question] : null}
        />
      ))}
      {!submitted && (
        <Button onClick={handleSubmit} variant="contained" color="primary">Submit Answers</Button>
      )}
      {submitted && !allCorrect && (
        <Button onClick={handleRetry} variant="contained" color="secondary">Retry</Button>
      )}
      {submitted && allCorrect && (
        <Button onClick={onNext} variant="contained" color="primary">Continue</Button>
      )}
    </Box>
  );
};

export default PictureQuiz;

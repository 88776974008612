import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '../styles/GlobalStyles.css';

const PersonalInfoPage = ({ language, setLanguage }) => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/lessons');
    };

    const handleBack = () => {
        navigate('/');
    };

    return (
        <div className="welcome-container" style={{ paddingLeft: '4vw', paddingRight: '4vw', paddingTop: '2vh' }}>
            <h1 className="welcome-header" style={{ marginBottom: '2vh', paddingTop: '0' }}>
                Let's begin!
            </h1>
            <Typography className="lesson-text" style={{ marginBottom: '1vh', paddingTop: '0' }}>
                What language do you want to begin learning today?
            </Typography>
            <form onSubmit={(e) => e.preventDefault()} className="form-container">
                <Grid container spacing={2} className="input-grid-container">
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Select Language"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="Spanish">Spanish</MenuItem>
                            <MenuItem value="Italian">Italian</MenuItem>
                            <MenuItem value="French">French</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <div className="form-button-container">
                    <Button variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '10px' }}>
                        Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleContinue}>
                        Continue
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default PersonalInfoPage;

// L1S2.js after refactoring
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import WordTable from '../../../components/WordTable.js';  // Adjust the import path as necessary

const L3S1 = ({ onNext, content, langCode, language }) => {
    const { header, words } = content;
    const description = `Below are the six basic words in ${language} we'll be covering in this lesson, along with their meaning. Please take a few seconds to look them over before moving on.`;

    return (
        <Box className="section-container">
            <Typography variant="h4" className='page-header'>{header}</Typography>
            <Typography variant="body1" sx={{ paddingTop: '0.8vh', paddingBottom: '1.5vh', paddingLeft: '1.5vw'}}>{description}</Typography>
            <Box className="tip-container">
                <Typography variant='h5' className='tip-header'> Top Tip:</Typography>
                <Typography variant='body2' className='tip-text'> You can click the speaker icon next to each word to hear how it's pronounced. Try it out!</Typography>
            </Box>
            <WordTable words={words} langCode={langCode} />
            <Button variant="contained" color="primary" onClick={onNext} className="continue-button" style={{ marginTop: '20px' }}>
                Continue
            </Button>
        </Box>
    );
};

export default L3S1;

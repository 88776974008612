// L1S1.js
import React from 'react';
import { Divider, Typography, Button } from '@mui/material';
import AnimatedSection from '../../../components/AnimatedSection';

const L1S1 = ({ onNext, content, language }) => {
  const { header } = content;

  const description = `Welcome to your first step in learning ${language}! In this lesson, we focus on essential phrases for everyday conversations. Get to know how to greet someone, introduce yourself, and say goodbye in ${language}.`;

  const contentElements = [
    <Typography className="lesson-title" key="title">{header}</Typography>,
    <Divider className="inset-divider" key="divider" />,
    <p className="lesson-text" key="text">{description}</p>,
    <Button key="button" variant="contained" color="primary" onClick={onNext} style={{ marginTop: '20px' }}>
      Continue
    </Button>
  ];

  return <AnimatedSection contentElements={contentElements} />;
};

export default L1S1;

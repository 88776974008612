// L1S4.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import MixAndMatch from '../../../components/MixAndMatch';

const L1S4 = ({ content, langCode, onNext }) => {
  const { header, words } = content;

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>{header || "Mix and Match"}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '3vh' }}>
        Drag the Spanish words to their English translations and submit for a score.
      </Typography>
      <MixAndMatch content={{ words }} langCode={langCode} onNext={onNext} />
    </Box>
  );
};

export default L1S4;

// L2S5.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import PictureQuiz from '../../../components/PictureQuiz';

const L2S5 = ({ onNext, content, langCode }) => {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>{content.header || "Quiz"}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '3vh' }}>
        In this short quiz, you'll answer questions based on the vocabulary you just learned.
      </Typography>
      <PictureQuiz
        quizData={content.quizData}
        onComplete={() => {}}
        onNext={onNext}
        langCode={langCode}
      />
    </Box>
  );
};

export default L2S5;

import React from 'react';
import { Box } from '@mui/material';

const AnimatedSection = ({ contentElements }) => {
  return (
    <Box sx={{
      overflow: 'hidden',
      width: '100%', // Confirm this matches the intended full width of the content
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%', // Ensures there is at least the minimum height to display all content
      boxSizing: 'border-box', // Includes padding and borders in width/height calculations
    }}>
      {contentElements.map((element, index) => (
        <div key={index} className="slide-up-fade-in" style={{ '--animation-delay': `${index * 0}s` }}>
          {element}
        </div>
      ))}
    </Box>
  );
};

export default AnimatedSection;

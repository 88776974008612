// L1S3.js
import React, { useState } from 'react';
import { Divider, Typography, Button, Box } from '@mui/material';
import AnimatedSection from '../../../components/AnimatedSection';
import WordTable from '../../../components/WordTable';

const L3S2 = ({ onNext, content, language, langCode }) => {
  const [step, setStep] = useState(0);

  const steps = content.words.map((word, index) => ({
    description: word.description,
    vocab: word,
  }));

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      onNext();
    }
  };

  return (
    <Box sx={{ alignSelf: 'flex-start' }}>
      <Typography variant="h4" className="page-header">
        Introducing {language} Basics
      </Typography>
      {steps.map((currentStep, index) => (
        <Box key={index}>
          {index <= step && (
            <AnimatedSection contentElements={[
              <Typography className="lesson-text description-text-new" key={`desc-${index}`}>{currentStep.description}</Typography>,
              <Box className="section-container" key={`box-${index}`} sx={{ width: '50vw'}}>
                <WordTable words={[currentStep.vocab]} langCode={langCode} key={`table-${index}`} />
              </Box>,
              index < steps.length - 1 && (
                <Divider className="inset-divider" variant='middle' key={`divider-${index}`} />
              ),
              index === step && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={{ marginTop: '20px' }}
                  key={`button-${index}`}
                >
                  {step < steps.length - 1 ? 'Next' : 'Continue'}
                </Button>
              ),
            ]} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default L3S2;

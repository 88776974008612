import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../styles/GlobalStyles.css';

// Import nested sections
import L1S1 from './sections/L1/L1S1';
import L1S2 from './sections/L1/L1S2';
import L1S3 from './sections/L1/L1S3';
import L1S4 from './sections/L1/L1S4';
import L1S5 from './sections/L1/L1S5';
import L1S6 from './sections/L1/L1S6';

import L2S1 from './sections/L2/L2S1';
import L2S2 from './sections/L2/L2S2';
import L2S3 from './sections/L2/L2S3';
import L2S4 from './sections/L2/L2S4';
import L2S5 from './sections/L2/L2S5';
import L2S6 from './sections/L2/L2S6';

import L3S1 from './sections/L3/L3S1';
import L3S2 from './sections/L3/L3S2';
import L3S3 from './sections/L3/L3S3';
import L3S4 from './sections/L3/L3S4';
import L3S5 from './sections/L3/L3S5';

import L4S1 from './sections/L4/L4S1';
import L4S2 from './sections/L4/L4S2';
import L4S3 from './sections/L4/L4S3';
import L4S4 from './sections/L4/L4S4';

const lessons = [
  [L1S1, L1S2, L1S3, L1S4, L1S5, L1S6],
  [L2S1, L2S2, L2S3, L2S4, L2S5, L2S6],
  [L3S1, L3S2, L3S3, L3S4, L3S5],
  [L4S1, L4S2, L4S3, L4S4],
];

const LessonPage = ({ language }) => {
  const navigate = useNavigate();
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [courseContent, setCourseContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      let contentModule;
      switch (language) {
        case 'Spanish':
          contentModule = await import('../content/Spanish.js');
          break;
        case 'Italian':
          contentModule = await import('../content/Italian.js');
          break;
        case 'French':
          contentModule = await import('../content/French.js');
          break;
        default:
          contentModule = await import('../content/Spanish.js');
      }
      setCourseContent(contentModule.default);
    };

    loadContent();
  }, [language]);

  if (!courseContent) return <div>Loading...</div>;

  const handleNext = () => {
    if (currentSectionIndex < lessons[currentLessonIndex].length - 1) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    } else if (currentLessonIndex < lessons.length - 1) {
      setCurrentLessonIndex(currentLessonIndex + 1);
      setCurrentSectionIndex(0);
    } else {
      navigate('/lessonfive');
    }
  };

  const handleBack = () => {
    if (currentSectionIndex > 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
    } else if (currentLessonIndex > 0) {
      setCurrentLessonIndex(currentLessonIndex - 1);
      setCurrentSectionIndex(lessons[currentLessonIndex - 1].length - 1);
    } else {
      navigate('/begin');
    }
  };

  const renderProgress = () => {
    return lessons.map((_, index) => (
      <div
        key={index}
        className={`progress-block ${index === currentLessonIndex ? 'current' : index < currentLessonIndex ? 'complete' : ''}`}
      />
    ));
  };

  const currentLesson = lessons[currentLessonIndex];
  const CurrentSectionComponent = currentLesson[currentSectionIndex];
  const currentLessonContent = courseContent.lessons[`L${currentLessonIndex + 1}`] || {};
  const currentSectionContent = currentLessonContent.sections[`S${currentSectionIndex + 1}`] || {};

  const sectionProps = {
    content: currentSectionContent,
    language: courseContent.language,
    langCode: courseContent.langCode,
    onNext: handleNext,
  };

  return (
    <Box className="lesson-container" sx={{ alignSelf: 'flex-start' }}>
      <Box className="lesson-nav-buttons" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2vh' }}>
        <Box>
          <Button onClick={handleBack} variant="contained" color="primary">Back</Button>
          <Button onClick={handleNext} variant="contained" className="skip-button">Skip</Button>
        </Box>
        <div className='progress-div'>
          <div className="progress-bar">
            {renderProgress()}
          </div>
        </div>
        <Typography variant="body2" color="text.secondary">
          Lesson {currentLessonIndex + 1} / {lessons.length}
        </Typography>
      </Box>
      <Box>
        <CurrentSectionComponent {...sectionProps} />
      </Box>
    </Box>
  );
};

export default LessonPage;

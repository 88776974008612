import React, { useState } from 'react';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import { Box, Button, Typography, Grid, IconButton, Paper } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../styles/GlobalStyles.css';

// Utility function to shuffle array
const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

const DraggableContainer = ({ sortable, index }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `sortable-${sortable.id}`,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    padding: '8px',
    margin: '4px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    maxWidth: '200px',
    border: `2px solid ${sortable.correct === true ? '#28a745' : sortable.correct === false ? '#dc3545' : '#ccc'}`, // Hex codes for green and red
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Typography>{sortable.text}</Typography>
    </Box>
  );
};

const DroppableCategory = ({ category, currentMatches }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `category-${category.id}`,
  });

  const style = {
    padding: '8px',
    margin: '4px',
    minHeight: '60px',
    backgroundColor: isOver ? '#e0f7fa' : '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    minWidth: '200px',
  };

  return (
    <Paper
      ref={setNodeRef}
      style={style}
    >
      <Typography variant="h6">{category.name}</Typography>
      <Box>
        {currentMatches.map((sortable, index) => (
          <Box key={index} sx={{ margin: '4px', padding: '4px', backgroundColor: '#fff', borderRadius: '4px', border: `2px solid ${sortable.correct === true ? '#28a745' : sortable.correct === false ? '#dc3545' : '#ccc'}` }}>
            <Typography>{sortable.text}</Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const SentenceSort = ({ content, onNext }) => {
  const [sortables, setSortables] = useState(shuffleArray(content.sortables.map(s => ({ ...s, correct: null }))));
  const [matches, setMatches] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [allCorrect, setAllCorrect] = useState(false);

  const handleDrop = (sortableId, categoryId) => {
    const sortable = sortables.find((s) => s.id === sortableId);
    if (sortable) {
      setMatches((prevMatches) => {
        const updatedMatches = { ...prevMatches };
        if (!updatedMatches[categoryId]) {
          updatedMatches[categoryId] = [];
        }
        updatedMatches[categoryId].push({ ...sortable });
        return updatedMatches;
      });
      setSortables((prevSortables) => prevSortables.filter((s) => s.id !== sortableId));
    }
  };

  const handleSubmit = () => {
    const newCorrectAnswers = {};
    let allCorrect = true;
    const incorrectSortables = [];

    // Evaluate sorted sortables
    Object.keys(matches).forEach((categoryId) => {
      matches[categoryId] = matches[categoryId].filter((sortable) => {
        if (sortable.categoryId === parseInt(categoryId)) {
          newCorrectAnswers[sortable.id] = true;
          return true;
        } else {
          newCorrectAnswers[sortable.id] = false;
          incorrectSortables.push(sortable);
          allCorrect = false;
          return false;
        }
      });
    });

    // Evaluate unsorted sortables
    sortables.forEach((sortable) => {
      newCorrectAnswers[sortable.id] = false;
      allCorrect = false;
    });

    // Reset incorrect sortables
    setSortables((prevSortables) => [
      ...prevSortables.map(s => ({ ...s, correct: newCorrectAnswers[s.id] === false ? false : newCorrectAnswers[s.id] === true ? true : s.correct })),
      ...incorrectSortables.map(s => ({ ...s, correct: false }))
    ]);

    // Mark all correct sortables correctly
    setSortables((prevSortables) => prevSortables.map((s) => ({
      ...s,
      correct: newCorrectAnswers[s.id] === true
    })));

    // Update matches with correct values
    setMatches((prevMatches) => {
      const updatedMatches = { ...prevMatches };
      Object.keys(updatedMatches).forEach((categoryId) => {
        updatedMatches[categoryId] = updatedMatches[categoryId].map((sortable) => ({
          ...sortable,
          correct: newCorrectAnswers[sortable.id] === true
        }));
      });
      return updatedMatches;
    });

    setAllCorrect(allCorrect);
    setIsSubmitted(true);
  };

  const handleReset = () => {
    setSortables(shuffleArray(content.sortables.map(s => ({ ...s, correct: null }))));
    setMatches({});
    setIsSubmitted(false);
    setAllCorrect(false);
  };

  return (
    <DndContext
      onDragEnd={({ active, over }) => {
        if (over) {
          const sortableId = parseInt(active.id.split('-')[1]);
          const categoryId = parseInt(over.id.split('-')[1]);
          handleDrop(sortableId, categoryId);
        }
      }}
    >
      <Box className="sentence-sort-container">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {sortables.map((sortable, index) => (
              <DraggableContainer key={sortable.id} sortable={sortable} index={index} />
            ))}
          </Grid>
          <Grid item xs={6}>
            {content.categories.map((category) => (
              <DroppableCategory
                key={category.id}
                category={category}
                currentMatches={matches[category.id] || []}
              />
            ))}
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '2vh', textAlign: 'center' }}>
          {!allCorrect && (
            <>
              <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: '2vh' }}>
                Submit
              </Button>
              <IconButton onClick={handleReset} sx={{ marginLeft: '1vh', color: 'primary.main' }}>
                <RefreshIcon />
              </IconButton>
            </>
          )}
          {isSubmitted && !allCorrect && (
            <Typography sx={{ marginTop: '2vh', color: 'red' }}>
              Some answers are incorrect. Please try again.
            </Typography>
          )}
          {isSubmitted && allCorrect && (
            <>
              <Typography sx={{ marginTop: '2vh', color: '#28a745' }}>
                All correct!
              </Typography>
              <Button onClick={onNext} variant="contained" color="primary" sx={{ marginTop: '2vh' }}>
                Continue
              </Button>
            </>
          )}
        </Box>
      </Box>
    </DndContext>
  );
};

export default SentenceSort;

// FillInTheBlanks.js
import React, { useState } from 'react';
import { Box, Typography, MenuItem, FormControl, Select, Button, Divider, ThemeProvider, createTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Maintain a simple theme for handling errors only
const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.Mui-error .MuiSelect-select': {
            borderColor: 'red'
          }
        }
      }
    }
  }
});

const FillInTheBlanksGame = ({ data, onNext }) => {
  const [answers, setAnswers] = useState(data.map(() => ""));
  const [correctStatus, setCorrectStatus] = useState(data.map(() => null));
  const [feedback, setFeedback] = useState("");
  const [showContinue, setShowContinue] = useState(false);

  const handleInputChange = (index, event) => {
    const newAnswers = [...answers];
    newAnswers[index] = event.target.value;
    setAnswers(newAnswers);
    const newStatus = [...correctStatus];
    newStatus[index] = null;
    setCorrectStatus(newStatus);
    setShowContinue(false);
  };

  const handleSubmit = () => {
    const newStatus = data.map((item, index) => item.answer.toLowerCase() === answers[index].toLowerCase());
    setCorrectStatus(newStatus);
    const allCorrect = newStatus.every(status => status);
    setFeedback(allCorrect ? "All correct! Very well done!" : "Not quite, try again!");
    setShowContinue(allCorrect);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ boxShadow: 'inset 0 4px 8px rgba(0,0,0,0.1)', backgroundColor: '#fff', borderRadius: '4px', position: 'relative', padding: '3vh 1.5vw', width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Fill in the blanks
        </Typography>
        <Divider className="fillintheblank-divider" />
        {data.map((item, index) => (
          <Box key={item.id} sx={{ marginBottom: '1.5vh', display: 'flex', alignItems: 'center', position: 'relative' }}>
            <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
              {item.text.split("[blank]").map((part, partIndex, array) => (
                partIndex < array.length - 1 ? <React.Fragment key={partIndex}>
                  {part}
                  <FormControl variant="standard" sx={{ m: 0.5, minWidth: '7vw', marginTop: '0vh', maginBottom: '0vh', position: 'relative' }} error={correctStatus[index] === false}>
                    <Select
                      value={answers[index]}
                      onChange={(event) => handleInputChange(index, event)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      disabled={correctStatus[index]}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {item.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option}>{option}</MenuItem>
                      ))}
                    </Select>
                    {correctStatus[index] !== null && (
                      <Box sx={{ position: 'absolute', top: '50%', left: '1vw', transform: 'translate(-100%, -50%)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {correctStatus[index] ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}
                      </Box>
                    )}
                  </FormControl>
                </React.Fragment> : part
              ))}
            </Typography>
          </Box>
        ))}
        {feedback && (
          <Typography variant="subtitle1" sx={{ marginTop: '1vh', color: correctStatus.every(status => status) ? 'green' : 'red' }}>
            {feedback}
          </Typography>
        )}
        {!showContinue && (
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginBottom: '1vh' }}>
            Submit
          </Button>
        )}
        {showContinue && (
          <Button variant="contained" color="primary" onClick={onNext} sx={{ marginTop: '2vh' }}>
            Continue
          </Button>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default FillInTheBlanksGame;

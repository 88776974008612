// L2S3.js
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import FillInTheBlanksGame from '../../../components/FillInTheBlank';

const L3S3 = ({ onNext, content }) => {

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 2, marginBottom: 2 }}>
        Let's play a fill-in-the-blank game!
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        Fill in the blanks with the correct words to complete the sentences.
      </Typography>
      <FillInTheBlanksGame data={content.words} onNext={onNext} />
    </Box>
  );
};

export default L3S3;

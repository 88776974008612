// ./sections/L1/L1S3.js
import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import CustomFlashcards from '../../../components/CustomFlashcards';
import AnimatedSection from '../../../components/AnimatedSection';

const L1S5 = ({ onNext, content, langCode }) => {
  const description = "Below are those same words in flashcard form. Please practice through the words at least once, making use of the pronunciation button below the flashcards.";
  const { header } = content;

  const contentElements = [
    <Typography className="lesson-title" key="title">{header}</Typography>,
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
        width: '100%',
        paddingBottom: '1vh',
      }}
    >
    <Typography
      key="description"
      align="center"
      style={{ width: '100%', fontSize: '1.05rem' }}
      className="lesson-text"
    >
      {description}
    </Typography> </Box>,
    <CustomFlashcards key="flashcards" cards={content.cards} langCode={langCode} />,
    <Button
      key="continue-button"
      variant="contained"
      color="primary"
      onClick={onNext}
      style={{ marginTop: '2vh', marginBottom: '1vh' }}
    >
      Continue
    </Button>
  ];

  return (
      <AnimatedSection contentElements={contentElements} />
  );
};

export default L1S5;

import React, { useState } from 'react';
import { DndContext, closestCenter, useDraggable, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { Box, Button, Paper, Typography, Grid, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import twemoji from 'twemoji';
import '../styles/GlobalStyles.css';

// Utility function to shuffle array
const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

const DraggableContainer = ({ word, id, index }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    padding: '8px',
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#d6eaff',
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    maxWidth: '200px',
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {word && <Typography>{word.altlang}</Typography>}
    </Box>
  );
};

const DroppableWord = ({ word, id, index, onDrop, currentMatch, correct }) => {
  const { isOver, setNodeRef, attributes } = useDroppable({
    id,
    data: { index },
  });

  const style = {
    padding: '8px',
    margin: '4px',
    minHeight: '40px',
    backgroundColor: isOver ? '#e0f7fa' : '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${correct === true ? 'green' : correct === false ? 'red' : '#ccc'}`,
    minWidth: '200px',
  };

  const emojiHTML = currentMatch ? twemoji.parse(currentMatch.emoji, { folder: 'svg', ext: '.svg' }) : '';

  return (
    <Paper
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Box
        sx={{
          width: '20vw',
          height: '30px',
          backgroundColor: '#e0e0e0',
          borderRadius: '4px',
          boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {currentMatch !== undefined && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '8px' }} dangerouslySetInnerHTML={{ __html: emojiHTML }} />
            <Typography>{currentMatch.altlang}</Typography>
          </Box>
        )}
      </Box>
      <Typography sx={{ marginLeft: '8px' }}>{word.english}</Typography>
    </Paper>
  );
};

const MixAndMatch = ({ content, langCode, onNext }) => {
  const [nonEnglishWords, setNonEnglishWords] = useState(shuffleArray([...content.words]));
  const [matches, setMatches] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState({});

  const handleDrop = (dragIndex, dropIndex, toSocket) => {
    if (toSocket) {
      setMatches((prevMatches) => {
        const updatedMatches = { ...prevMatches };
        const movedWord = nonEnglishWords[dragIndex];
        const replacedWord = updatedMatches[dropIndex];
        updatedMatches[dropIndex] = movedWord;
        setNonEnglishWords((prevWords) => {
          if (replacedWord) {
            return [...prevWords, replacedWord].filter((_, index) => index !== dragIndex);
          }
          return prevWords.filter((_, index) => index !== dragIndex);
        });
        return updatedMatches;
      });
    } else {
      const movedWord = matches[dragIndex];
      setMatches((prevMatches) => {
        const updatedMatches = { ...prevMatches };
        delete updatedMatches[dragIndex];
        return updatedMatches;
      });

      setNonEnglishWords((prevWords) => {
        const newWords = [...prevWords];
        newWords.splice(dropIndex, 0, movedWord);
        return newWords.filter(Boolean);
      });
    }
  };

  const handleSubmit = () => {
    setCorrectAnswers({});
    setIsSubmitted(true);
    const newCorrectAnswers = {};
    const newNonEnglishWords = [...nonEnglishWords];
    const newMatches = { ...matches };

    Object.keys(matches).forEach((key) => {
      if (matches[key].altlang === content.words[key].altlang) {
        newCorrectAnswers[key] = true;
      } else {
        newCorrectAnswers[key] = false;
        newNonEnglishWords.push(matches[key]);
        delete newMatches[key];
      }
    });

    setCorrectAnswers(newCorrectAnswers);
    setNonEnglishWords(newNonEnglishWords);
    setMatches(newMatches);
  };

  const handleReset = () => {
    setNonEnglishWords(shuffleArray([...content.words]));
    setMatches({});
    setIsSubmitted(false);
    setCorrectAnswers({});
  };

  const allCorrect = Object.keys(correctAnswers).length === content.words.length && Object.values(correctAnswers).every(Boolean);

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={({ active, over }) => {
        if (over) {
          const activeIndex = active.data.current.sortable ? active.data.current.sortable.index : active.data.current.index;
          const overIndex = over.data.current.sortable ? over.data.current.sortable.index : over.data.current.index;

          if (over.data.current.sortable) {
            handleDrop(activeIndex, overIndex, false);
          } else {
            handleDrop(activeIndex, overIndex, true);
          }
        }
      }}
    >
      <Box className="mix-match-container" sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '98%' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SortableContext items={nonEnglishWords.map((_, index) => `draggable-${index}`)} strategy={rectSortingStrategy}>
                {nonEnglishWords.map((word, index) => (
                  <DraggableContainer key={index} word={word} id={`draggable-${index}`} index={index} />
                ))}
              </SortableContext>
            </Grid>
            <Grid item xs={6}>
              {content.words.map((word, index) => (
                <DroppableWord
                  key={index}
                  word={word}
                  id={`droppable-${index}`}
                  index={index}
                  onDrop={handleDrop}
                  currentMatch={matches[index]}
                  correct={isSubmitted ? correctAnswers[index] : null}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: '2vh', textAlign: 'center' }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{ marginTop: '2vh' }}
          >
            Submit
          </Button>
          <IconButton onClick={handleReset} sx={{ marginLeft: '1vh', color: 'primary.main' }}>
            <RefreshIcon />
          </IconButton>
          {isSubmitted && (
            <Typography sx={{ marginTop: '2vh', color: allCorrect ? 'green' : 'red' }}>
              {allCorrect ? 'All matches are correct!' : 'Some matches are incorrect.'}
            </Typography>
          )}
          {isSubmitted && allCorrect && (
            <Button
              onClick={onNext}
              variant="contained"
              color="primary"
              sx={{ marginTop: '2vh' }}
            >
              Continue
            </Button>
          )}
        </Box>
      </Box>
    </DndContext>
  );
};

export default MixAndMatch;

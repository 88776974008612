import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import PersonalInfoPage from './pages/InfoPage';
import LessonPage from './pages/LessonPage';
import CompletePage from './pages/Finished';
import Footer from './components/Footer';

function App() {
  const [language, setLanguage] = useState('Spanish');

  return (
    <Router>
      <div className="main-content">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/begin" element={<PersonalInfoPage language={language} setLanguage={setLanguage} />} />
          <Route path="/lessons" element={<LessonPage language={language} />} />
          <Route path="/lessonfive" element={<CompletePage />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

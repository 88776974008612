// components/WordTable.js
import React from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import twemoji from 'twemoji';
import { speakWord } from '../utils/SpeakWords';  // Adjust path if needed

const WordTable = ({ words, langCode }) => {
    const speakerEmoji = twemoji.parse('🔊', { folder: 'svg', ext: '.svg' });

    return (
        <TableContainer component={Paper} sx={{ marginTop: '0vh', marginBottom: '1vh', maxWidth: '70%' }}>
            <Table aria-label="simple table">
                <TableBody>
                    {words.map((word, index) => {
                        const wordEmoji = twemoji.parse(word.emoji, { folder: 'svg', ext: '.svg' });
                        return (
                            <TableRow key={index}>
                                <TableCell align="left" style={{ fontSize: '1.2rem' }}>
                                    <Tooltip title="Hear word">
                                        <Button
                                            onClick={() => speakWord(word.altlang, langCode)}
                                            style={{ padding: '5px', marginRight: '10px', maxHeight: '2vh' }}
                                        >
                                            <Box sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'left',
                                                justifyContent: 'center',
                                                height: '4vh'
                                            }} dangerouslySetInnerHTML={{ __html: speakerEmoji }}>
                                            </Box>
                                        </Button>
                                    </Tooltip>
                                    {word.altlang}
                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                                        {word.english} 
                                        <Box sx={{ width: '2rem', paddingLeft: '0.5vw' }}><Box dangerouslySetInnerHTML={{ __html: wordEmoji }} /></Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WordTable;

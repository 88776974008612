import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import hook for navigation
import '../styles/GlobalStyles.css';

const WelcomePage = () => {
    const navigate = useNavigate(); // Hook for navigation

    const handleBeginClick = () => {
        navigate('/begin'); // Navigate to the personal info page
    };

    return (
        <div className="welcome-container">
            <h1 className="welcome-header">Welcome to Korembi LMS Beta Demo!</h1>
            <img 
                src={require('../korembi-full.webp')} 
                alt="Korembi Logo" 
                className="welcome-logo" 
            />
            <p style={{ maxWidth: '80%' }}>This demo includes a handful of hand-curated lessons, a demonstration of Korembi's AI-powered LMS features, 
            and a small display of Korembi LMS's learning process.</p>
            <p style={{ paddingBottom: '1vh' }}>
                Click the button below to begin!
            </p>
            <button className='continue-button' onClick={handleBeginClick}>Click to Begin</button>
        </div>
    );
};

export default WelcomePage;

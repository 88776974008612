// ../../../components/CustomFlashcards.js
import React, { useState, useRef, useEffect } from 'react';
import { Typography, IconButton, Grid, Box } from '@mui/material';
import { FlashcardArray } from 'react-quizlet-flashcard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import twemoji from 'twemoji';
import { speakWord } from '../utils/SpeakWords';

const CustomFlashcards = ({ cards, langCode }) => {
  const controlRef = useRef({});
  const [currentCard, setCurrentCard] = useState(1);
  const [cardSides, setCardSides] = useState(() => {
    // Initialize all cards as showing their front
    return cards.reduce((acc, card) => {
      acc[card.id] = true;
      return acc;
    }, {});
  });

  useEffect(() => {
    console.log(`moved to card ${currentCard}: currently on front? ${cardSides[currentCard]}`);
  }, [currentCard, cardSides]);

  const handlePrevCard = () => {
    if (currentCard > 1) {
      setCurrentCard(currentCard - 1);
      controlRef.current.prevCard();
    }
  };

  const handleNextCard = () => {
    if (currentCard < cards.length) {
      setCurrentCard(currentCard + 1);
      controlRef.current.nextCard();
    }
  };

  const handleSpeak = () => {
    const isFront = cardSides[currentCard];
    const cardContent = isFront ? cards[currentCard - 1].front : cards[currentCard - 1].back.text;
    speakWord(cardContent, langCode);
    console.log(`card front? ${isFront}`);
  };

  const handleCardFlip = (isFlipped) => {
    // Update the state explicitly by setting true/false directly
    setCardSides(prev => {
      const newSides = { ...prev };
      newSides[currentCard] = !newSides[currentCard]; // Toggle front/back
      return newSides;
    });
  };

  // Process cards to include emojis using twemoji and proper separation
  const cardsFormatted = cards.map(card => {

    const frontStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      fontSize: '3.5rem',
      marginRight: '10px',
    };

    const backStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      paddingTop: '2vh',
    };

    const parsedEmoji = twemoji.parse(card.back.emoji, { folder: 'svg', ext: '.svg' });

    return {
      id: card.id,
      frontHTML: (
        <div style={frontStyle}>
          {card.front}
        </div>
      ),
      backHTML: (
        <Box sx={backStyle}>
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20%', paddingTop: '3vh', paddingBottom: '2vh' }} dangerouslySetInnerHTML={{ __html: parsedEmoji }} />
          <Box sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: '-10px' }}>
            <Typography variant="body1" className="card-back-text">{card.back.text}</Typography>
          </Box>
        </Box>
      ),
    };
  });

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <FlashcardArray
          cards={cardsFormatted}
          cardIndex={currentCard}
          controls={false}
          showCount={false}
          forwardRef={controlRef}
          onCardFlip={(isFlipped) => handleCardFlip(isFlipped)}
          onCardChange={(id, index) => setCurrentCard(index)}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton onClick={handlePrevCard} disabled={currentCard === 1}>
              <ArrowBackIosIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontSize: '1.3rem' }}>
              {currentCard} / {cards.length}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleNextCard} disabled={currentCard === cards.length}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={handleSpeak}>
              <VolumeUpIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomFlashcards;

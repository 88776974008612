// ConversationalQuiz.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControl, FormControlLabel, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import twemoji from 'twemoji';
import { speakWord } from '../utils/SpeakWords';  // Adjust path if needed

const emojiList = ['👳', '👩‍🦱', '🧑‍🔧', '🤵‍♂️', '👩‍💼', '👨‍💼', '🧑‍💼', '👨‍🦳'];  // Add more as needed

const getRandomEmoji = () => {
    const randomIndex = Math.floor(Math.random() * emojiList.length);
    return emojiList[randomIndex];
};

const ConversationalQuiz = ({ quizData, onNext, langCode }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [showFeedback, setShowFeedback] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [randomEmoji, setRandomEmoji] = useState(getRandomEmoji());

    const currentQuestion = quizData[currentQuestionIndex];

    useEffect(() => {
        setRandomEmoji(getRandomEmoji());
        console.log('Component Loaded:', { currentQuestionIndex, selectedAnswer, isCorrect });
    }, [currentQuestionIndex]);

    const handleAnswerChange = (event) => {
        const answer = event.target.value;
        setSelectedAnswer(answer);
        console.log('Answer Changed:', { answer });
    };

    const handleSubmit = () => {
        if (selectedAnswer === currentQuestion.correctAnswer) {
            setIsCorrect(true);
            setShowFeedback(true);
            console.log('Correct Answer:', { selectedAnswer, correctAnswer: currentQuestion.correctAnswer });
        } else {
            setIsCorrect(false);
            setShowFeedback(true);
            console.log('Incorrect Answer:', { selectedAnswer, correctAnswer: currentQuestion.correctAnswer });
        }
    };

    const handleNext = () => {
        if (currentQuestionIndex < quizData.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedAnswer('');
            setShowFeedback(false);
            setIsCorrect(false);
        } else {
            onNext();
        }
    };

    return (
        <Box className="mix-match-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Box sx={{ width: '50%', textAlign: 'center', padding: '20px' }}>
                <Typography variant="h4" gutterBottom>Conversation</Typography>
                <Box><Box sx={{ fontSize: '5rem', width: '10vw' }} dangerouslySetInnerHTML={{ __html: twemoji.parse(randomEmoji, { folder: 'svg', ext: '.svg' }) }} /></Box>
                <Typography 
                    variant="h5" 
                    sx={{ 
                        backgroundColor: '#d8d8d8', 
                        padding: '10px', 
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        borderTopLeftRadius: '0',
                        maxWidth: '60%'
                    }}>
                    {currentQuestion.phrase}
                </Typography>
            </Box>
            <Box sx={{ width: '50%', padding: '20px' }}>
                <Box sx={{ border: `1px solid ${isCorrect ? 'green' : showFeedback ? 'red' : '#ccc'}`, borderRadius: '10px', padding: '2rem' }}>
                    {showFeedback && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                            {isCorrect ? (
                                <CheckCircleOutlineIcon color="success" />
                            ) : (
                                <HighlightOffIcon color="error" />
                            )}
                            <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                                {isCorrect ? 'Correct!' : 'Incorrect, try again.'}
                            </Typography>
                        </Box>
                    )}
                    <Typography variant="h6" gutterBottom>
                        {`${currentQuestionIndex + 1}) ${currentQuestion.question}`}
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
                            {currentQuestion.options.map((option, index) => (
                                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginTop: '20px' }}>
                        {!isCorrect && (
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="secondary"
                            >
                                Submit
                            </Button>
                        )}
                        {showFeedback && isCorrect && (
                            <Button
                                onClick={handleNext}
                                variant="contained"
                                color="primary"
                            >
                                {currentQuestionIndex === quizData.length - 1 ? 'Continue' : 'Next Question'}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ConversationalQuiz;

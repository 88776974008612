import React from 'react';
import { Typography, Container } from '@mui/material';
import '../styles/GlobalStyles.css';

const CompletionPage = () => {
    return (
        <Container className="welcome-container" sx={{ padding: '30px'}}>
            <Typography variant="h4" className="welcome-header">
                Demo Complete!
            </Typography>
            <Typography variant="subtitle1" sx={{ paddingBottom: '25px', paddingTop: '15px'}}>
                You just completed the Korembi multi-language customizable demo! This was a short demo that utilized audio, visual and kinesthetic cues to cater to every kind of student.
            </Typography>
            <Typography variant="subtitle1">
                This includes pairing emoji-like icons with words for visual association, using audio-based exercises to better-assist auditory learners, and making use of in-depth exercises that help the user recursively use previously-learnt words, phrases, and concepts for a better learning experience.
                This was a very short demonstration of Korembi's in-depth customizable course capabilities, including soon-to-come A.I. features, nonlinear content-based courses, and further language support!
            </Typography>
            <Typography sx={{ paddingTop: '15px'}}>
            Stay tuned for more!
            </Typography>
        </Container>
    );
};

export default CompletionPage;

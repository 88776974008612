import React from 'react';
import { Box, Typography } from '@mui/material';
import SentenceSort from '../../../components/SentenceSort';

const L4S3 = ({ content, langCode, onNext }) => {
  const { header, sortables, categories } = content;

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>{header || "Sort the Sentences"}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '3vh' }}>
        Drag the phrases to the correct categories and submit for a grade.
      </Typography>
      <SentenceSort content={{ sortables, categories }} langCode={langCode} onNext={onNext} />
    </Box>
  );
};

export default L4S3;

// L1S7.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import ConversationalQuiz from '../../../components/ConversationalQuiz';

const L3S5 = ({ onNext, content, langCode }) => {
  const quizData = content.quizData || [];

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>{content.header || "Quiz"}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: '3vh' }}>
        In this short quiz, you'll answer questions based on the vocabulary you just learned.
      </Typography>
      <ConversationalQuiz
        quizData={quizData}
        onNext={onNext}
        langCode={langCode}
      />
    </Box>
  );
};

export default L3S5;
